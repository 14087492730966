<template>
  <div class="home">
    <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
    <herobig v-bind:title="$t('title')"></herobig>

    <div class="row">
      <div class="col-1"></div>
      <div class="col px-4">
        <p v-if="$store.state.lingua=='en'" class="text-center border border-dark rounded mb-3 p-1 fs-16">
          To make your stay in Colorno – Parma easier… we suggest you to 
          <a class="fs-16 font-weight-bold" href="https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/SY000001tvY9/S5sKTeaO1Vhg2iufas_bBE7onvWgy9h2Ayf_IcI5ldw">
            download this flyer!
          </a>
        </p>
      </div>
      <div class="col-1"></div>
    </div>


    <h2 class="welcome">My Barcode</h2>

    <boxcontainer>

        <div class="row mx-auto">
          <div class="col d-md-block d-none"></div>
          <div class="col text-center">
            {{nome}}
            <svg id="barcode"></svg>
          </div>
          <div class="col d-md-block d-none"></div>
        </div>

        <div class="row mx-auto">
          <div class="col"></div>
          <div class="col text-center">
            {{matr}}
          </div>
          <div class="col"></div>
        </div>

    </boxcontainer>
    
    <h2 class="welcome">PIN:</h2>
    <div class="container">
      <div class="row mx-auto">
        <div class="col px-0 text-center">
          {{pin}}
        </div>
      </div>
    </div>

    <!-- PORTE -->
    <!-- <h2 class="pt-3 pb-2 mt-2 text-center border-top font-weight-bold">{{ $t('PulsantiApriPorte') }}</h2>
    <div v-if="!accessoCancelli" class="alert mx-4 text-center">{{ $t('SbloccoApriPorte') }}</div>
    <div v-if="attivaGPS" class="alert alert-danger mx-4 text-center">{{ $t('attivaGPS') }}</div>
    <div class="container" v-if="accessoCancelli">
      <h2 class="font-weight-bold">{{ $t('porteEsterne') }}</h2>
      <div class="row mx-0">
        <div class="col-6 text-center">
          <button class="btn-primary panel-title rounded-pill" :class="buttonDisable.cancello ? 'disabled': ''"
          v-on:click="openPorta('Cancello')">{{ $t('cancello') }}</button>
          <h6>Dist: {{ myDistCancello }}m</h6>
        </div>
        <div class="col-6 text-center">
          <button class="btn-primary panel-title rounded-pill" :class="buttonDisable.spogliatoio ? 'disabled': ''"
          v-on:click="openPorta('Spogliatoio')">{{ $t('spogliatoio') }}</button>
          <h6>Dist: {{ myDistSpogliatoio }}m</h6>
        </div>
      </div>
      <h2 class="font-weight-bold">{{ $t('porteInterne') }}</h2>
      <div class="row mx-0">
        <div class="col-12 px-0 text-center">
          <button class="btn-primary panel-title rounded-pill" :class="buttonDisable.spogliatoioInterno ? 'disabled': ''"
          v-on:click="openPorta('SpogliatoioInterno')">{{ $t('spogliatoioInterno') }}</button>
          <h6>Dist: {{ myDistSpogliatoioInt }}m</h6>
        </div>
      </div>
    </div> -->

    <!-- PORTE -->
    <h2 class="pt-3 pb-2 mt-2 text-center border-top font-weight-bold">
      {{ $t('PulsantiApriPorte') }} <i :class="reloadingLocation ? 'spinner-border spinner-border-sm' : 'bi bi-arrow-clockwise'" v-on:click="doLoc()"></i>
    </h2>
    <div v-if="!accessoCancelli" class="alert mx-4 text-center">{{ $t('SbloccoApriPorte') }}</div>
    <div v-if="attivaGPS" class="alert alert-danger mx-4 text-center">{{ $t('attivaGPS') }}</div>
    <div class="container" v-if="accessoCancelli">

      <h2 class="font-weight-bold">{{ $t('porteEsterne') }}</h2>
      <div class="row mx-0 mt-2">
        <div class="col-2 px-0 text-center">
          <a v-on:click="buttonDisable.cancello ? doLoc() : ''">
            <button class="btn-primary panel-title rounded-pill px-3" :class="buttonDisable.cancello ? 'disabled': ''"
            v-on:click="openPorta('Cancello')"><i class="bi bi-door-open-fill"></i></button>
          </a>
        </div>
        <div class="col-10 align-content-center">
          <div class="row text-uppercase ml-0">{{ $t('cancello') }}</div>
          <div class="row ml-0" :class="buttonDisable.cancello ? '': 'text-success font-weight-bold'">
            <p>(Dist: {{ myDistCancello }}m)</p>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-2 px-0 text-center">
          <a v-on:click="buttonDisable.spogliatoio ? doLoc() : ''">
            <button class="btn-primary panel-title rounded-pill px-3" :class="buttonDisable.spogliatoio ? 'disabled': ''"
            v-on:click="openPorta('Spogliatoio')"><i class="bi bi-door-open-fill"></i></button>
        </a>
        </div>
        <div class="col-10 align-content-center">
          <div class="row text-uppercase ml-0">{{ $t('spogliatoio') }}</div>
          <div class="row ml-0" :class="buttonDisable.spogliatoio ? '': 'text-success font-weight-bold'">
            <p>(Dist: {{ myDistSpogliatoio }}m)</p>
          </div>
        </div>
      </div>

      <h2 class="font-weight-bold">{{ $t('porteInterne') }}</h2>
      <div class="row mx-0">
        <div class="col-2 px-0 text-center">
          <a v-on:click="buttonDisable.spogliatoioInterno ? doLoc() : ''">
            <button class="btn-primary panel-title rounded-pill px-3" :class="buttonDisable.spogliatoioInterno ? 'disabled': ''"
            v-on:click="openPorta('SpogliatoioInterno')"><i class="bi bi-door-open-fill"></i></button>
        </a>
        </div>
        <div class="col-10 align-content-center">
          <div class="row text-uppercase ml-0">{{ $t('spogliatoioInterno') }}</div>
          <div class="row ml-0" :class="buttonDisable.spogliatoioInterno ? '': 'text-success font-weight-bold'">
            <p>(Dist: {{ myDistSpogliatoioInt }}m)</p>
          </div>
        </div>
      </div>
    </div>

    <!-- ARMADIETTO -->
    <h2 v-if="numArmadietto" class="welcome pt-3 pb-2 mt-2 mx-2 border-top">{{ $t('Armadietto') }}:</h2>
    <div class="container" v-if="numArmadietto">
      <div class="row mx-0">
        <div class="col px-0 pb-1 text-center">
          {{ numArmadietto }}
          <br>{{ $t('assegnatoFinoAl') }} {{ armadiettoLiberoDal }}
        </div>
      </div>
    </div>

    <div v-if="books != ''">
      <!-- <div class="row mx-auto">
        <div class="col"></div>
        <div class="col-sm-12 col-md-10">
          <hr />
        </div>
        <div class="col"></div>
      </div> -->
    
      <div class="text-center">
        <u><a :href="guidebook" target="_blank">
          <h2 class="welcome pt-3 pb-2 mt-2 mx-2 border-top">My Digital Books <i class="fa fa-info-circle" style="font-size:18px"></i></h2>
        </a></u>
      </div>
    
      <boxcontainer>
            <div class="pt-2 card text-center" v-for="b in books" v-bind:key="b.index">
              <div class="card-body">
                <h5 class="card-title"><a target="_blank" href="https://www.bsmart.it/" class="fs-16">{{b.Titolo_libro__c}} <i class="fa fa-info-circle"></i></a></h5>
                <div class="input-group mb-3 justify-content-center">
                  <input type="text" class="form-control btncb" readonly :value="b.Codice_libro__c">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" @click="copyCode(b.Codice_libro__c)">
                      <i class="fa fa-clipboard" :id="b.Codice_libro__c"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
      </boxcontainer>
    </div>

    <footerline></footerline>

  </div>
</template>

<script>
  // @ is an alias to /src
  import navbar from '@/components/navbar.vue'
  import herobig from '@/components/herobig.vue'
  import boxcontainer from '@/components/boxcontainer.vue'
  import footerline from '@/components/footerline.vue'
  import JsBarcode from 'jsbarcode'
  
  export default {
    name: 'Utility',
    components: {
      navbar,
      herobig,
      boxcontainer,
      footerline,
    },
    data: function() {
      return {
        matricola: 'Matr. 123/20',
        books: [],

        ufficio_lat: 44.6357444,
        ufficio_lng: 10.8994511, 

        reloadingLocation: false,

        // cancello_lat: 44.9309499, // 44.9301581,10.3758494,21z
        // cancello_lng: 10.3776571,
        cancello_lat: 44.93015234362793, // 44.9301581,10.3758494,21z
        cancello_lng: 10.37579272874666,
        myDistCancello: 0,

        // spogliatoi_lat: 44.930178, //44.930178,10.3762582,21z
        // spogliatoi_lng: 10.3762582,
        spogliatoi_lat: 44.93103810124558, //44.930178,10.3762582,21z
        spogliatoi_lng: 10.377292921776302,
        myDistSpogliatoio: 0,

        spogliatoioInt_lat: 44.9309458, //aggiornate al 09/09/2024
        spogliatoioInt_lng: 10.3775089,
        myDistSpogliatoioInt: 0,

        buttonDisable : {
          cancello: true,
          spogliatoio: true,
          spogliatoioInterno: true
        },

        myCoordinate: 0,

        chiamaDoLoc: true,
        attivaGPS: false,
      }
    },
    computed: {
      nome: function() {
        if (this.$store.state.userprofile) {
          return this.$store.state.userprofile.FirstName+' '+this.$store.state.userprofile.LastName;
        }
        return '';
      },
      code: function() {
        if (this.$store.state.userprofile) {
          return this.$store.state.userprofile.Matricola__c;
        }
        return '';
      },
      pin: function() {
        if (this.$store.state.userprofile) {
          return this.$store.state.userprofile.Pin__c;
        }
        return '';
      },
      matr: function() {
        if (this.$store.state.userprofile) {
          return this.$store.state.userprofile.Matricola__c.slice(1)
        }
        return '';
      },
      numArmadietto: function() {
        // console.log('numArmadietto al momento disabilitato (return sempre false)')
        if (this.$store.state.opptyCorso) {
          console.log('numArmadietto primo if')
          if(this.$store.state.opptyCorso.Armadietto__c) {
            console.log('numArmadietto',this.$store.state.opptyCorso.Armadietto__r.Name)
            return this.$store.state.opptyCorso.Armadietto__r.Name;
            // return false
          }
          return false
        }
        return false;
      },
      armadiettoLiberoDal: function() {
        if (this.$store.state.opptyCorso) {
          var date = this.$store.state.opptyCorso.Armadietto__r.Libero_Dal__c
          date = date.split("-").reverse().join("-");
          return date;
        }
        return '';
      },
      accessoCancelli: function() { 
        if(this.$store.state.opptyCorso.IsActiveAperturaPorte__c // caso studente
        || (this.$store.state.userprofile.Categoria__c.includes('Dipendente') 
            && this.$store.state.userprofile.Abilitazioni_Webapp__pc.includes('Apertura Porte'))) 
        { 
          // console.log('pulsanti cancelli sempre disabilitati (return sempre false)')
          if (navigator.geolocation) {
            this.doJob();
          } else {
            alert("Geolocation API is not supported in your browser.");
          }
          return true;
        }
        return false
      },
      guidebook: function () {
        if (this.$i18n.locale == 'it')
          return 'https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/5J000001ZzGE/Rk2zoS1ikv7dJHHzsFTUcLNd.I51grMQ10.Futt_Q5w' // SANDBOX LINK: 'https://almascuola--dev2021.my.salesforce.com/sfc/p/0Q0000008ipI/a/0Q0000005CRs/GBCqR3Lctwu8LLV36nI7TrbSZoRjIkuvyjtWSgsCuD8'
        else
          return 'https://almascuola.my.salesforce.com/sfc/p/1t000000ss9x/a/5J000001ZzGJ/81Gd1m_1GQBds4vo2KsiZqNbxHdrgdfognV3nsoPPzk' // SANDBOX LINK: 'https://almascuola--dev2021.my.salesforce.com/sfc/p/0Q0000008ipI/a/0Q0000005CRn/BHOjkK6hk0FrTl7BDjCAgg6I8gXOcqN0W0ZdOZwwUvA'
      }
    },
    mounted: function() {
      JsBarcode("#barcode", this.code, {
        format: "CODE39",
        displayValue: false
      });

      this.$store.dispatch('getLibri').then(() => this.books = this.$store.state.libri)
    },
    methods: {
      copyCode: function(c) {
        navigator.clipboard.writeText(c)
        document.getElementById(c).classList.replace('fa-clipboard', 'fa-clipboard-check')
      },
      async doJob() {
        while(this.chiamaDoLoc) {
          await this.doLoc();
          await this.doPause(1000);
        }
      },
      distance (lat1, lng1, lat2, lng2) {
        var R = 6371e3; // metres
        var phi1 = lat1 * Math.PI / 180; // phi1,phi2 in radians
        var phi2 = lat2 * Math.PI / 180;
        var deltaphi = (lat2 - lat1) * Math.PI / 180;
        var deltalambda = (lng2 - lng1) * Math.PI / 180;
        
        var a = Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltalambda / 2) * Math.sin(deltalambda / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        
        var d = R * c; // in metres
        
        return d ; // in km
      }, 
      doLoc() {
        var self = this;
        self.chiamaDoLoc = false
        self.reloadingLocation = true
        return new Promise( function(resolve, reject) {
          navigator.geolocation.getCurrentPosition(function(position) {
              console.log('position.coords.accuracy', position.coords.accuracy);
              console.log('position.coords.latitude', position.coords.latitude);
              console.log('position.coords.longitude', position.coords.longitude);
              let msg="lat:"+position.coords.latitude+" long:"+position.coords.longitude+" acc:"+position.coords.accuracy;
              console.log(msg)

              // UFFICIO GN
              let d=self.distance(self.ufficio_lat,self.ufficio_lng, position.coords.latitude, position.coords.longitude);
              if (d<=20) {
                console.log('<b>sei in ufficio!'+d+'</b><br/>')
              }

              // CANCELLO
              d=self.distance(self.cancello_lat,self.cancello_lng, position.coords.latitude, position.coords.longitude);
              if (d<=50) {
                console.log('<b>sei al cancello d\'ingresso!'+d+'</b><br/>')
                self.buttonDisable.cancello = false
              }
              self.myDistCancello = d.toFixed(2)

              // SPOGLIATOIO
              d=self.distance(self.spogliatoi_lat,self.spogliatoi_lng, position.coords.latitude, position.coords.longitude);
              if (d<=50) {
                console.log('<b>sei dagli spogliatoi!'+d+'</b><br/>')
                self.buttonDisable.spogliatoio = false
              }
              self.myDistSpogliatoio = d.toFixed(2)

              // SPOGLIATOIO INTERNO
              d=self.distance(self.spogliatoioInt_lat, self.spogliatoioInt_lng, position.coords.latitude, position.coords.longitude);
              if (d<=50) {
                console.log('<b>Sei dallo spogliatoio interno!'+d+'</b><br/>')
                self.buttonDisable.spogliatoioInterno = false
              }
              self.myDistSpogliatoioInt = d.toFixed(2)

              self.myCoordinate="lat:"+position.coords.latitude+" long:"+position.coords.longitude+" acc:"+position.coords.accuracy;

              self.reloadingLocation = false
              resolve();
            },
            function error(msg) {
              console.log('error: '+msg);
              self.attivaGPS = true
              reject(msg);
            },
            { timeout:20000, enableHighAccuracy: true }
          )
        })
      },
      doPause(sleep) {
        return new Promise( function(resolve) {
          setTimeout(function() {
            resolve();
          }, sleep)
        });
      },
      openPorta(porta) {
        console.log('porta da aprire: '+porta)
        // console.log('Cosa bisogna fare ora?')
        this.buttonDisable[porta] = true
        this.$store.dispatch('saveApriPorta', {porta: porta})
        .then(resp => {
          console.log('saveApriPorta resp',resp)
          alert('Comando di apertura inviato. Se la porta non dovesse aprirsi contattare la segreteria.')
          this.buttonDisable[porta] = false
        })
      }
    }
  }
</script>

<style scoped>
  .fs-16 {
    font-size: 16px;
  }

  .btncb {
    max-width: 150px;
  } 
</style>