<template>
    <div class="home">
        <navbar :menulist="$store.state.mainmenu" />

        <div class="row mx-auto pt-2">
            <div class="col-lg-12 col-md-12 col-xs-12 ml-2">
                <button type="button" class="btn btn-outline-dark btn-sm" @click="gotoBack">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16" style="vertical-align: sub">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>
                    {{$t('Indietro')}}
                </button>
            </div>
        </div>

        <div :class="{disabled: !this.$store.getters.isEcommerceVisible}">
        <h2 class="welcome">{{$t('subtitleAcquisti')}}</h2>

        <!-- RIGA PER INPUT, ICONA CARRELLO -->
        <div v-if="showprods || showscheda" class="row mx-auto d-flex justify-content-center pb-4">
            <div class="col-lg-8 col-md-8 col-sm-10 col-xs-12">
                <div class="input-group">
                    <!-- bottone desktop -->
                    <div class="dropdown" :class="{show: showFiltri}">
                        <button
                            class="btn btn-outline-primary dropdown-toggle d-none d-md-block d-lg-block"
                            id="dropdownMenuFiltri" type="button" data-bs-toggle="dropdown"
                            :aria-expanded="showFiltri" @click="showFiltri = !showFiltri"
                        >
                            {{ criterio || $t('FiltraPer') }}
                        </button>
                        <button
                            class="btn btn-primary dropdown-toggle d-block d-sm-block d-md-none"
                            id="dropdownMenu2" type="button" data-bs-toggle="dropdown"
                            :aria-expanded="showFiltri" @click="showFiltri = !showFiltri"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                            </svg>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuFiltri"
                            :class="{show: showFiltri, menuOpen: showFiltri}"
                        >
                            <li v-for="f in filtri" :key="f.label">
                                <a class="dropdown-item">
                                    {{$t(f.label)}}
                                </a>
                                <ul class="dropdown-menu dropdown-submenu position-absolute">
                                    <li v-for="o in f.options" :key="o" @click="setFiltro(o, f.label)">
                                        <a class="dropdown-item">
                                            {{$t(o)}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" @click="setFiltro('', 'RESET')">{{$t('Reset')}}</a></li>
                        </ul>
                    </div>
                    <!-- fine bottone desktop -->

                    <!-- bottone mobile -->
                    <!-- <div class="dropdown d-block d-sm-block d-md-none" :class="{show: dropdownShow2}">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                @click="dropdownShow2 = !dropdownShow2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                            </svg>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2" :class="{show: dropdownShow2, menuOpen: dropdownShow2}">
                            <button
                                class="dropdown-item" type="button" @click="setCategoria(c)"
                                v-for="c in $store.state.categoriaEcommerce" :key="c"
                            >
                                {{c}}
                            </button>
                        </div>
                    </div> -->
                    <!-- fine bottone mobile -->
                    
                    <input
                        type="text" class="form-control" aria-label="Cerca qualsiasi cosa"
                        v-model="search" :placeholder="$t('CercaProdotto')"
                    />
                    <button type="button" class="btn btn-primary" @click="getProdotti(search, filtro, criterio)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewbox="0 0 16 16">
                            <path  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </button>
                    <button type="button" class="btn btn-outline-primary" style="margin-left: 20px" @click="gotoCarrello2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart4" viewbox="0 0 16 16">
                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                        </svg>
                        <span v-if="totqty > 0" class="badge badge-pill badge-warning badge-cart mt-1 position-absolute">{{ totqty }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row mx-auto" v-if="isLoading">
            <img class="loadingImg" :src="loading" alt="caricamento...">
        </div>

        <div v-if="showprods">
            <!-- BLOCCO griglia prodotti -->
            <div class="row mx-auto pb-2">
                <template v-for="prod in prods">
                    <div
                        class="col-lg-2 col-md-3 col-sm-10 col-xs-10 mb-3"
                        :key="prod.ProductCode" v-if="prod.page == currentPage"
                    >
                        <div class="card h-100 pointer bordo-oro" @click="gotoScheda(prod, $event)">
                            <div class="p-3">
                                <div class="grigliaCommerceDiv2 text-center align-content-center">
                                    <img
                                        class="mw-100 mh-100"
                                        :id="prod.Id" :src="imgs[prod.Id]"
                                    >
                                </div>
                            </div>

                            <div class="card-body bordox">
                                <p class="card-text font-weight-bold">{{prod.Name}}
                                    <span v-if="prod.Info_Prenotazione__c"><br>{{prod.Info_Prenotazione__c}}</span>
                                </p>
                            </div>
                            <div class="bg-alma p-3">
                                <p class="card-text text-white">
                                    {{prod.Prezzo__c | amount}}
                                    <span class="float-right">
                                        <span class="pr-2">INFO</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                        </svg>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- ... -->
            </div>

            <!-- num pagine -->
            <div class="row mx-auto">
                <div class="col-lg-12 col-md-12 col-xs-8 mt-5">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" @click="changePage(currentPage-1)" :hidden="currentPage === 1">
                                <a href="#" class="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li class="page-item" :class="{active: p.sel}" v-for="p in pages" :key="p.page">
                                <p class="page-link" :value="p.page" @click="changePage(p.page)">
                                    {{p.page}}
                                </p>
                            </li>
                            <li class="page-item" @click="changePage(currentPage+1)" :hidden="currentPage === pages.length">
                                <a href="#" class="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <!-- fine -->
        </div>
        <!-- FINE PRIMA RIGA DI PRODOTTO -->

        <div v-if="showscheda">
            <shopproduct
                :prod="prodSelected" :taglie="prodSelected.taglie || []"
                :mTaglieVarianti="prodSelected.mTaglieVarianti"
                :numeri="prodSelected.taglie || []"
                @gotoCarrello="gotoCarrello" @addedToCart="showDone = true"
            ></shopproduct>
        </div>

        <div v-if="showcarrello">
            <shopcart v-bind:imgs="imgs" :shoppers="shoppers" :ricamo="ricamo"></shopcart>
        </div>

        <!-- aggiunto al carrello -->
        <div v-show="showDone" class="modal fade show text-center" :class="{'show': showDone}">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content modal-md">
                    <div class="modal-body">
                        <svg class="check-icon-done" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"/>
                        </svg>
                        <p>{{$t('AggiuntoCarrello')}}</p>
                        <button class="btn btn-light" @click="showDone = false">OK</button>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <footerline />
    </div>
</template>

<script>
    // @ is an alias to /src
    import navbar      from '@/components/navbar.vue'
    import footerline  from '@/components/footerline.vue'
    import shopproduct from '@/components/shopproduct.vue'
    import shopcart    from '@/components/shopcart.vue'

    import numeral from 'numeral'

    const noimgsrc = require('../assets/no-image-found-ecommerce.png'),
          loading  = require('../assets/loading-dots.gif')

    export default {
        name: 'EcommerceShop',
        components: {
            navbar,
            footerline,
            shopproduct,
            shopcart
        },
        data: function() {
            return {
                boxes: [],
                prods: [],
                pages: [],
                currentPage: 1,
                search: '',
                showprods:    true,
                showscheda:   false,
                showcarrello: false,
                prodSelected: {},
                shoppers: [],
                ricamo: {},

                // images
                noimage: noimgsrc,
                loading: loading,
                imgs: {},

                // dropdown handlers
                dropdownShow1: false,
                dropdownShow2: false,

                showFiltri: false,
                filtri: {
                    cats:  { label: 'Categorie', options: [] },
                    // corsi: { label: 'Corsi',     options: [] }
                },
                filtro: '', criterio: '',

                isLoading: false,
                showDone:  false,

                cat: 0
            }
        },
        computed: {
            totqty: function() {
                var t = 0

                if (this.$store.state.carrelloEcommerce.length) this.$store.state.carrelloEcommerce.forEach(p => t += p.qty)
                
                return t
            }
        },
        filters: {
            amount: function(v) {
                return numeral(v).format('0,0[.]00 $')
            }
        },
        mounted: function() {
            this.getCat().then(() => {
                this.getProdotti('', '', '', true)
                this.loadAllImages()
            })

            this.getCarrello()
            this.getFiltri()
        },
        methods: {
            // go to
            gotoBack: function() {
                this.prodSelected = {}
                if (this.showprods) this.$router.push({ name: 'EcommerceHome' })
                if (this.showscheda) {
                    this.showprods    = true
                    this.showscheda   = false
                    this.showcarrello = false
                }
                if (this.showcarrello) {
                    this.showcarrello = false

                    if (Object.keys(this.prodSelected).length === 0) {
                        this.showscheda = false
                        this.showprods  = true
                    } else {
                        this.showscheda = true
                        this.showprods  = false
                    }
                }
            },
            gotoScheda: function(p, e) { 
                console.log('gotoScheda')
                console.log('p',p)
                console.log('e',e)
                this.prodSelected = p
                this.showprods    = false
                this.showscheda   = true
                this.showcarrello = false
            },
            gotoCarrello: function(prod) {
                console.log('gotoCarrello')
                this.$store.dispatch('addProd', prod)
                this.showprods    = false
                this.showscheda   = false
                this.showcarrello = true
            },
            gotoCarrello2: function() {
                this.prodSelected = {}
                this.isLoading    = false
                this.showprods    = false
                this.showscheda   = false
                this.showcarrello = true
            },

            // getters
            getProdotti: function(ricerca, filtro, crit, firstTime) {
                console.log('F --> getProdotti')
                this.showprods = true
                this.isLoading = true
                this.showscheda = false

                console.log(`ricerca: '${ricerca}', filtro: '${filtro}', criterio: '${crit}'`)

                // if (filtro == 'Categorie') crit = (crit === 'Tutte le categorie' ? '' : crit)

                this.prods = []
                this.shoppers = []

                ricerca = (ricerca != '' ? '%' + ricerca + '%' : ricerca)

                var payload = { ricerca: ricerca, filtro: filtro, crit: crit }//, self = this
                console.log('payload', payload)

                this.$store.dispatch('getProdEcommerce', payload)
                .then(resp => {
                //  var tempPage = Math.ceil(resp.length / 6)
                //  Array(tempPage).fill(0).forEach((d, i) => {
                //      var p = { sel: i == 0 ? true : false, page: i + 1 }
                //      this.pages.push(p)
                //  })
                    // console.log('prodotti resp: ', resp)

                    if (resp) {
                        console.log('prodotti resp: ', resp)

                        var abProds = {}
                        var prodConRicamo = {}

                        for(var d of resp) {
                        // resp.forEach((d, /*i*/) => {
                            // if(d.Tempo_approvvigionamento__c) console.log('id: '+d.Id+', name: '+d.Name+', temApp: '+d.Tempo_approvvigionamento__c)
                            //if (d.ContentDocumentLinks) d.ContentDocumentLinks.records.forEach(r => ids.push(r.ContentDocumentId))
                            if(d.Family == 'Shopper') {
                                d.Prezzo__c = d[`Prezzo_${this.cat}__c`]
                                this.shoppers.push(d)
                                continue
                            }

                            if(d.Family == 'Ricamo') {
                                d.Prezzo__c = d[`Prezzo_${this.cat}__c`]
                                this.ricamo = d
                                continue
                            }

                            d.statoDispo = d.Quantita_Disponibile__c > 0 ? 'In Stock' : 'Prenotabile'

                            switch (d.Family) {
                                case 'Libri':
                                    d.showLingua = true
                                    break;
                                case 'Torta':
                                    d.showDetCibo = true // allergeni?
                                    d.statoDispo = d.Quantita_Disponibile__c > 0 ? 'In Stock': 'Esaurito'
                                    break;
                                case 'Abbigliamento':
                                    //d.showTaglie = true
                                    d.scarpa = d.Description.includes('SCARP') ? true : false // ?
                                    break;
                            }

                            var c = d.Codici_Corso__c
                            if (c) d.corsi = c.split(';')

                            d.LastModifiedDate = new Date(d.LastModifiedDate)
                            // d.img = undefined

                            d.Attachments ? d.hasImage = true : d.hasImage = false

                            //d.page = Math.ceil((i + 1) / 6)

                            d.Prezzo__c = d[`Prezzo_${this.cat}__c`]

                            if (d.Family === 'Abbigliamento') {
                                var name = ''
                                if(d.Note__c && d.Note__c != '') name = d.Note__c
                                else name = d.Name

                                if(d.Possibilita_Ricamo__c) prodConRicamo[name] = d.Possibilita_Ricamo__c

                                if (Object.keys(abProds).includes(name)) {
                                    abProds[name].variants.push(d)
                                    abProds[name].mTaglieVarianti[d.Sesso_Taglia__c.substring(1)] = d
                                } else {
                                    abProds[name] = {
                                        Id: d.Id,
                                        variants: [d],
                                        taglie: [],
                                        scarpa: d.scarpa,
                                        mTaglieVarianti : {},
                                        LastModifiedDate: new Date(1990, 1, 1),
                                        hasImage: false,
                                        Codice_Aliquota__c: d.Codice_Aliquota__c,
                                        Regime_Iva__c: d.Regime_Iva__c,
                                        Tempo_approvvigionamento__c: d.Tempo_approvvigionamento__c
                                    }
                                    abProds[name].mTaglieVarianti[d.Sesso_Taglia__c.substring(1)] = d
                                }
                            } else this.prods.push(d)
                        }
                        console.log('prodotti: ', resp)
                        
                        Object.values(abProds).forEach((p) => {
                            // console.log('p e i',p,i)
                            p.variants.forEach(v => {
                                if(v.Sesso_Taglia__c != '**') p.taglie.push(v.Sesso_Taglia__c.substring(1))

                                var tmpDate = new Date(v.LastModifiedDate)
                                if (tmpDate > p.LastModifiedDate) p.LastModifiedDate = tmpDate

                                if (v.hasImage) {
                                    p.Id = v.Id
                                    p.hasImage = true
                                }
                                if (v.Codice_Aliquota__c) p.Codice_Aliquota__c = v.Codice_Aliquota__c
                                if (v.Regime_Iva__c) p.Regime_Iva__c = v.Regime_Iva__c
                            })

                            if(p.scarpa) {
                                p.taglie = p.taglie.sort(function(a, b){return a - b})
                                console.log('p.taglie',p.taglie)
                            }else p.taglie = this.sortTaglie(p.taglie)

                            var name = ''
                            if(p.variants[0].Note__c && p.variants[0].Note__c != '') name = p.variants[0].Note__c
                            else name = p.variants[0].Name

                            var prod = {
                                ProductCode: p.variants[0].ProductCode,
                                // ProductCode : `AB${i}`,
                                Id: p.Id,
                                Family: 'Abbigliamento',
                                LastModifiedDate: p.LastModifiedDate,
                                hasImage: p.hasImage,
                                // campi presi dalla prima variante
                                // Name: p.variants[0].Name,
                                Name: name,
                                Description: p.variants[0].Description,
                                Prezzo__c: p.variants[0][`Prezzo_${this.cat}__c`],
                                corsi: p.variants[0].corsi,
                                scarpa: p.variants[0].scarpa,
                                // taglie e varianti
                                taglie: p.taglie,
                                variants: p.variants,
                                Codice_Aliquota__c: p.Codice_Aliquota__c,
                                Regime_Iva__c: p.Regime_Iva__c,
                                Tempo_approvvigionamento__c: p.Tempo_approvvigionamento__c,
                                mTaglieVarianti: p.mTaglieVarianti,
                                possibilitaRicamo: prodConRicamo[name],
                            }
                            if(prodConRicamo[name]) prod['testoRicamo'] = ''
                            // if(prod.taglie.length > 0) prod.ProductCode = `AB${i}`

                            this.prods.push(prod)
                        })

                        this.reorder() // reorder per LastModifiedDate
                        this.pagination()
                        if(firstTime) this.loadAllImages()

                        //this.$forceUpdate()
                    }
                }).then(() => {
                    if (ricerca == '' && filtro == '' && crit == '') this.getFiltri() // TODO: fa schifo
                    this.isLoading = false
                })
            },
            getCarrello: function() {
                this.$store.dispatch('recuperaCarrello')
            },
            getPicklistValues: function(o, f) {
                if (this.$store.state.campi) {
                    if (this.$store.state.campi.has(o)) {
                        console.log('oggetto', o)
                        
                        var af = this.$store.state.campi.get(o)
                        if (af.has(f)) {
                            console.log('campo', f)

                            var afv = af.get(f)
                            console.log('afv', afv)

                            if (afv) return afv.values.split(';')
                        }
                    }
                }
                return []
            },
            getImages: async function(prods) {
                console.log('getImages in EcommerceShop.vue')
                // this.imgs = {}

                await prods.forEach( p => {
                    if (p.hasImage) {
                        console.log('EcommercSshop.getImages',p.Name, p.id)
                        this.$store.dispatch('getImage', {id: p.Id, limit: !this.showscheda})
                        .then(res => {
                            console.log('getImage res', res)
                            this.$set(this.imgs, p.Id, res[0])
                        })
                    } else this.$set(this.imgs, p.Id, this.noimage)
                })
                console.log('numero di immagini (this.imgs)', Object.keys(this.imgs).length)
            },
            getFiltri: function() {
                this.getCategoria()
                // this.getCorsi()
            },
            async getCategoria() {
                var soql = 'SELECT Family FROM Product2 WHERE Disponibile_Ecommerce__c = true GROUP BY Family'
                await this.$store.dispatch('getCategoria', soql)
                this.filtri.cats.options = this.$store.state.categoriaEcommerce
            },
            getCorsi() {
                var corsi = []

                this.prods.forEach(p => { if (p.corsi) p.corsi.forEach(c => { if (!corsi.includes(c)) corsi.push(c) })})

                this.filtri.corsi.options = corsi
            },

            // altro
            changePage: function(t) {
                this.currentPage = t
                this.pages.forEach(d => {
                    d.page === t ? d.sel = true : d.sel = false
                })

                var ps = []
                this.prods.forEach(p => {
                    if (p.page === this.currentPage) ps.push(p)
                })

                this.getImages(ps)
            },
            setFiltro: function(f, lab) {
                this.criterio = f
                this.filtro   = (lab != 'RESET') ? lab : ''

                this.showFiltri   = false
                this.showscheda   = false
                this.showcarrello = false

                switch (lab) {
                    case 'Categorie':
                        this.getProdotti(this.search, lab, f)
                        break
                    case 'Corsi':
                        this.getProdotti(this.search, lab, f)
                        break
                    case 'RESET':
                        this.getProdotti('', '', '')
                        break
                }
            },
            pagination: async function() {
                this.pages = []

                var tempPage = Math.ceil(this.prods.length / 6), ps = []

                Array(tempPage).fill(0).forEach((d, i) => {
                    var p = { sel: i == 0 ? true : false, page: i + 1 }
                    this.pages.push(p)
                })

                this.prods.forEach((p, i) => {
                    p.page = Math.ceil((i + 1) / 6)

                    if (p.page == this.currentPage) ps.push(p)
                })

                await this.getImages(ps)
            },
            loadAllImages: async function() {
                var ps = []

                this.prods.forEach((p) => {
                    ps.push(p)
                })

                await this.getImages(ps)
            },
            reorder: function() {
                this.prods.sort((a, b) => b.LastModifiedDate - a.LastModifiedDate)
            },
            sortTaglie: function(array) {
                var w = {"10XS":1,"9XS":2,"8XS":3,"7XS":4,"6XS":5,"5XS":6,"4XS":7,"3XS":8,"2XS":9,"XS":10,"S":11,"M":12,"L":13,"XL":14,"2XL":15,"3XL":16,"4XL":17,"5XL":18,"6XL":19,"7XL":20,"8XL":21,"9XL":22,"10XL":23}

                return array.sort((a, b) => w[a] - w[b])
            },

            getCat: async function() {
                if (this.$store.getters.diplomato)
                    this.cat = 1
                if (this.$store.getters.studente)
                    this.cat = 2
                if (this.$store.getters.dipendente)
                    this.cat = 3
            }
        }
    }
</script>

<style>
    .bordo-oro { border: 1px solid #d29500 !important; }

    .grigliaCommerceDiv2 { height: 161px; }

    .menuOpen {
        transform: translate3d(0px, 38px, 0px);
        top: 0px;
    }

    .badge-cart {
        font-size: .46rem;
        right: 4px;
    }

    .loadingImg {
        height: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .check-icon-done { height: 100px; }

    .dropdown-menu .dropdown-submenu {
        left: 100%;
        top: -3px;
    }

    .dropdown-menu > li:hover > .dropdown-submenu { display: block; }

    .show > .btn-primary.dropdown-toggle {
        background-color: #d29500 !important;
        border-color: #d29500 !important;
    }

    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
</style>