<template>
  <div class="home">
  <h2 class="welcome">{{ titolo }}</h2>
    <p v-if="modbloccate" class="welcomeWarning text-danger">{{ $t('bloccomodifiche') }}</p>

    <boxcontainer>
      <div v-if="!showPiatto">
        <div class="row bordotondo mb-3 mt-2">
          <div v-if="this.$i18n.locale=='it'" class="col">
            Per visualizzare le linee guida clicca <u><a style="font-size:16px" class="font-weight-bold" href="https://www.alma.scuolacucina.it/gli-esami-finali/" target="_blank">qui</a></u>
          </div>
          <div v-else class="col">
            To view the guidelines click <u><a style="font-size:16px" class="font-weight-bold" href="https://www.alma.scuolacucina.it/en/final-exam/" target="_blank">here</a></u>
          </div>
        </div>
        <div class="row bordotondo" v-if="!idFile" style="margin-bottom: 5px">
          <div class="col-8">
            <span>{{ $t('NoDocumento') }}</span>
            <p>{{ $t('TesiInt') }}</p>
          </div>
          <div class="col-4 colright">
            <button type="button" class="btn btn-primary" @click.prevent="uploadDocument('Tesi')" :disabled="modbloccate">{{ $t('CaricaTesi') }}</button>
            <p>{{ $t('Limite') }}</p>
          </div>
        </div>
        <div class="row" v-if="idFile">
          <div class="col-8">
            {{$t('NomeFileTesi')}} <b>{{filetesi}}</b>
          </div>
          <div class="col-4 colright">
            <button type="button" class="btn btn-primary" @click.prevent="uploadDocument('Tesi')" :disabled="modbloccate">{{ $t('ModificaTesi') }}</button>
            <p>{{$t('Limite')}}</p>
          </div>
        </div>

        <!-- DOSSIER -->
        <div v-if="tipo == 'Esame Finale'" class="row" :class="idFileDoss ? '' : 'bordotondo'">
          <div v-if="idFileDoss" class="col-8">
              {{$t('NomeFileDossier')}} <b>{{fileDoss}}</b>
          </div>
          <div v-else class="col-8">
              <span>{{$t('NoDocumentoDossier')}}</span>
              <p>{{$t('DossierInt')}}</p>
          </div>
          <div class="col-4 colright">
            <button type="button" class="btn btn-primary" @click.prevent="uploadDocument('Dossier di Tirocinio')" :disabled="dossierBloccato">{{$t('ModificaDossier')}}</button>
            <p>{{$t('Limite')}}</p>
          </div>
        </div>

        <div class="text-center mt-3">
          <h3>{{$t('BuoniOrd')}}</h3>
          <h4 v-if="showDeadline" class="text-danger font-weight-bold">Deadline: {{ dataBloccoModificheTesi }}</h4>
        </div>

        <div v-if="costoEnable && this.$store.state.tesi.totaleTesi" class="row pt-2 colpiatto">
          <div class="card col-sm-12 col-xs-12 col-md-10" :class="{ fuoriBudget: fuoriBudget }">
            <div class="row" style="padding:2%">
              <div class="col-5" style="text-align:left;margin-top:2%">
                <h5 :class="{ textRed: fuoriBudget }">{{ $t('TotaleTesi') }}:</h5>
              </div>
              <div class="col-3" style="text-align:right;margin-top:2%">
                <h5 :class="{ textRed: fuoriBudget }">{{ this.$store.state.tesi.totaleTesi }}€</h5>
              </div>
              <div v-if="this.budgetComplessivoTesi" class="col-4" style="text-align:center">
                <div :class="{ textRed: fuoriBudget }">
                  budget<br>{{ this.budgetComplessivoTesi }}€
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-2 colpiatto" v-for="piatto of piatti" :key="piatto.id">
          <div class="card col-sm-12 col-xs-12 col-md-10">
            <div class="card-body">
              <h5 class="card-title">{{ piatto.Name }}</h5>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-9 row" v-if="costoEnable && piatto.totalePiatto" style="margin-right:1%">
                    <div class="col-7" style="padding-top:3%">
                      <h6>{{ $t('TotalePiatto') }}:</h6>
                    </div>
                    <div class="col-5" style="text-align:right;padding-top:2%">
                        <h5>{{ piatto.totalePiatto }}€</h5>
                    </div>
                  </div> 
                  <div v-else class="col-9"></div>
                  <div class="col-3" style="text-align:right;">
                    <a v-if="!modbloccate" href="" class="btn btn-primary float-right" @click.prevent="editPiatto(piatto)">{{$t('Modifica')}}</a>
                    <a v-if="modbloccate"  href="" class="btn btn-primary float-right" @click.prevent="editPiatto(piatto)">{{$t('Visualizza')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row bordotondo " style="margin-top: 4px;" v-if="condvuote && !modbloccate">
          <div class="col-sm-12 col-md-12 ">
            <button type="button" class="btn btn-primary float-right" @click.prevent="aggiungiPiatto">{{$t('AggiungiPiatti')}}</button>
          </div>
        </div>
      </div>

      <div class="bordotondo piatto" v-if="showPiatto">
        <div class="row">
          <div class="col-9">
            <label>{{$t('Nomedelpiatto')}}</label>
          </div>
          <div v-if="linkguidacorso" class="col-3 colright">
            <u><a @click="gotoUtility" target="_blank"><p style="font-size: 16px">{{$t('GuidaMaterie')}}</p></a></u>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input :class="!descpiatto?'is-invalid':'is-valid'" v-model="descpiatto" :disabled="modbloccate" required type="text" class="form-control" @input="descpiatto = descpiatto.toUpperCase()" :placeholder="$t('InserisciPiatto')" />
            </div>
          </div>
        </div>
        <div class="row" v-if="tipi.length > 0">
          <div class="col-9">
            <label>{{$t('TipoPiatto')}}</label>
          </div>
          <div class="col-1">
          </div>
        </div>
        <div class="row" v-if="tipi.length > 0">
          <div class="col-12">
            <div class="form-group">
                  <select v-model="tiposcelto" :disabled="modbloccate"
                    class="form-control" :class="!tiposcelto?'is-invalid':'is-valid'"
                    :title="$t('Scegliunaopzione')" required>
                    <option v-for="v in tipi" :key="v" :value="v">{{$t(v)}}</option>
                  </select>
            </div>
          </div>
        </div>

        <div v-if="costoEnable && this.$store.state.tesi.totaleTesi" class="card text-center col-sm-12 col-xs-12 col-md-12"  :class="{ fuoriBudget: fuoriBudget }" style="margin-bottom:1%">
          <div class="row" style="padding:1%;text-align:center;">
            <div class="col-4">
              <h6 :class="{ textRed: fuoriBudget }" style="margin-bottom:0">{{$t('TotaleTesi')}}:</h6>
            </div>
            <div class="col-4">
                <h6 :class="{ textRed: fuoriBudget }" style="margin-bottom:0">
                  {{ this.$store.state.tesi.totaleTesi }}€
                </h6>
            </div>
            <div v-if="this.budgetComplessivoTesi" class="col-4">
                <h6 :class="{ textRed: fuoriBudget }" style="margin-bottom:0">
                  / {{ this.budgetComplessivoTesi }}€
                </h6>
            </div>
          </div>
        </div>

        <div v-if="costoEnable">
        <div v-for="piatto of piatti" :key="piatto.Id">
          <div v-if="piatto.Id == idpiatto && piatto.totalePiatto" class="text-center card col-sm-12 col-xs-12 col-md-12" style="margin-bottom:0">
            <h5 style="padding:2%;margin:0">{{ $t('TotalePiatto') }}: {{ piatto.totalePiatto }} €</h5>
          </div>
        </div>
        </div>
        <tabellapiatto v-if="ingredientiNec"  :blocco="modbloccate" :tipo="'Ingrediente'"  :costoEnable="costoEnable" :condId="$store.state.condizione.Id" :ingredienti="ingredienti"  :noneed="noneedIng"  :titolo="$t('Ingredienti')"  :step=".001" @add="aggiungi('i')" @del="rimuovi('i',$event)" @update="calcolaTotale($event)" @changeneed="noneedIng  = !noneedIng"></tabellapiatto>
        <tabellapiatto v-if="attrezzaturaNec" :blocco="modbloccate" :tipo="'Attrezzatura'" :costoEnable="costoEnable" :condId="$store.state.condizione.Id" :ingredienti="attrezzature" :noneed="noneedAttr" :titolo="$t('Attrezzatura')" :step="1"    @add="aggiungi('a')" @del="rimuovi('a',$event)" @changeneed="noneedAttr = !noneedAttr"></tabellapiatto>

        <div class="row pt-2">
          <div class="col-3">
            <button type="submit" class="btn btn-outline-secondary" @click.prevent="indietroPiatto">{{$t('Indietro')}}</button>
          </div>

          <div class="col-5"></div>

          <div v-if="costoEnable && this.fuoriBudget" class="col-4 text-right">
            <button type="submit" disabled class="btn btn-primary">{{$t('Fuori Budget')}}</button>
          </div>
          <div v-else class="col-4 text-right">
            <button type="submit" :disabled="checkPanel" class="btn btn-primary" @click.prevent="salvaPiatto">{{$t('Salva')}}</button>
          </div>
        </div>
      </div>

      <modalupload :show="uploadShow" :tipoFile="tipoFile" :id="idDocument" @submit="uploadDone" @cancel="uploadCancel"></modalupload>
    </boxcontainer>

    <div class="col text-center mt-3">
      <button class="btn btn-primary" @click="$router.push({ name: 'Interventi', params: {from: 'Esami'} })">{{ $t('NeedHelp') }}</button>
    </div>

    <footerline></footerline>
  </div>
</template>

<script>
  import boxcontainer   from '@/components/boxcontainer.vue'
  import footerline     from '@/components/footerline.vue'
  import tabellapiatto  from '@/components/tabellapiatto.vue'
  import modalupload    from '@/components/modalupload.vue'

  export default {
    name: 'tesi',
    components: {
      boxcontainer,
      footerline,
      tabellapiatto,
      modalupload
    },
    props: {
      titolo:         String,
      tipo:           String,
      uploadDossier:  Boolean,
    },
    data: function () {
      return {
        idDocument: '',
        uploadShow: false,
        idFile:     '',
        filetesi:   '',
        idFileDoss: '',
        fileDoss:   '',
      //   tesi:       {},
        totaleTesi: 0,
        numPiatti:  0,
        budgetComplessivoTesi: 0,
        piatti:     [],
        showPiatto: false,

        idpiatto:           '',
        nomepiatto:         '',
        descpiatto:         '',
        ordinepiatto:       0,
        ingredienti:        [],
        valoriIngredienti:  [],
        attrezzature:       [],
        valoriAttrezzature: [],
        tipi:               [],
        tiposcelto:         '',
        ingredientiNec:     true,
        attrezzaturaNec:    true,
        condvuote:          false,
        dossierBloccato:    false,
        linkguidacorso:     '',
        tipoFile:           '',

        noneedIng:  false,
        noneedAttr: false,
        // costoEnable: false
      };
    },
    mounted: function () {
      this.$store.getters.tesi
      this.loadTesi()
    },
    computed: {
      checkPanel: function() {
        if (this.modbloccate) return true
        if (!this.descpiatto) return true
        if (!this.tiposcelto) return true
        if (this.fuoriBudget) return true

        if (this.ingredienti) {
          var blocca = false

          this.ingredienti.forEach(ing => {
            if (!ing.Note__c || !ing.Quantita__c || !ing.Unita_di_Misura__c) blocca = true
          })

          if (!this.noneedAttr) {
            this.attrezzature.forEach(attr => {
              if (!attr.Note__c || !attr.Quantita__c || !attr.Unita_di_Misura__c) blocca = true
            })
          }

          return  blocca
        }
        return false
      },
      fuoriBudget: function () {
        if (this.budgetComplessivoTesi && parseFloat(this.$store.state.tesi.totaleTesi) > parseFloat(this.budgetComplessivoTesi)) return true
        return false
      },
      costoEnable: function() {
        if (this.$store.state.condizione.Budget_Complessivo__c) return true //così si attiva sia per esami intermedi sia per finali
        return false 
      },
      dataBloccoModificheTesi: function() {
        return this.$store.state.tesi.Data_blocco_modifiche_tesi__c.split('-').reverse().join('-')
      },
      databloc() {
        return new Date(this.$store.state.tesi.Data_blocco_modifiche_tesi__c)
      },
      oggi() {
        return new Date().setHours(0)
      },
      modbloccate() {
        return this.databloc < this.oggi ? true : false
      },
      showDeadline () {
        return this.databloc > new Date().setFullYear(new Date().getFullYear() + 1) ? false : true
      }
    },
    methods: {
      aggiungiPiatto: function() {
        this.ordinepiatto = this.piatti.length + 1
        this.idpiatto     = ''
        this.nomepiatto   = ''
        this.ingredienti  = []
        this.attrezzature = []
        this.noneedAttr   = false
        this.noneedIng    = false

        this.showPiatto = true
      },
      indietroPiatto: function() {
        this.showPiatto = false
      },
      salvaPiatto: function() {
        console.log('--> salvaPiatto')
        this.modbloccate = true

        var piatto = {
          Tesi__c:                this.$store.state.tesi.Id,
          Tipo_Piatto__c:         this.tiposcelto,
          Descrizione_Piatto__c:  this.descpiatto,
          Ordinatore__c:          this.ordinepiatto,

          No_ingredienti_richiesti__c:  this.noneedIng,
          No_attrezzature_richieste__c: this.noneedAttr
        }

        if (this.idpiatto) piatto.Id = this.idpiatto

        var ingatt = []

        this.ingredienti.forEach((ing, i) => {
          console.log('ing', ing)
          console.log('i',i)
          console.log('valori', ing.valori[0].Id)
          var o = { //Ingrediente/Attrezzatura
            Ordinatore__c:      i + 1,
            Name:               ing.Note__c ? ing.Note__c.substr(0, 80) : '',
            Note__c:            ing.Note__c,
            Quantita__c:        ing.Quantita__c,
            Tipo__c:            'Ingrediente',
            Codice_Arca__c:     ing.Codice_Arca__c,
            Unita_di_Misura__c: ing.Unita_di_Misura__c,
            Categoria__c:       ing.Categoria__c,
            Prodotto__c:        ing.Prodotto__c,
            Ignora_Costo__c:    ing.Ignora_Costo__c
          }

          // if(ing.Costo_Quantita__c == 0) o.Ignora_Costo__c = true

          if (ing.Id) o.Id = ing.Id

          ingatt.push(o)
        })

        this.attrezzature.forEach((ing, i) => {
          var o = {
            Ordinatore__c:      i + 1,
            Name:               ing.Note__c ? ing.Note__c.substr(0, 80) : '',
            Note__c:            ing.Note__c,
            Quantita__c:        ing.Quantita__c,
            Tipo__c:            'Attrezzatura',
            Codice_Arca__c:     ing.Codice_Arca__c,
            Unita_di_Misura__c: ing.Unita_di_Misura__c,
            Categoria__c:       ing.Categoria__c,
            Ignora_Costo__c:    ing.Ignora_Costo__c
          }

          if (ing.Id) o.Id = ing.Id

          ingatt.push(o)
        })

        console.log('ingatt')
        console.log(ingatt)

        console.log('setPiatto salvaPiatto')
        this.$store.dispatch('setPiatto', { piatto: piatto, ingredienti: ingatt }).then(resp => {
          console.log(resp)
          this.loadTesi()
        })
      },
      loadTesi: function() {
        // var self = this
        console.log('--> loadTesi')
        this.$store.dispatch('getTesi',this.tipo).then(() => {
          console.log('UNA sola tesi')
          console.log('this.$store.state.tesi',this.$store.state.tesi)
          if (this.$store.state.tesi.Id) {
            console.log('tesi caricata')

            // this.$set(this.$store.state.tesi, 'totaleTesi', 0)
            this.totaleTesi = 0
            this.numPiatti = 0
            if (this.$store.state.tesi.Piatti__r) {
              this.piatti = this.$store.state.tesi.Piatti__r.records
              console.log('this.piatti before getProdottiPiatto',this.piatti)
              for(var piatto of this.piatti) {
                this.getProdottiPiatto(piatto.Id)
              }
              console.log('this.piatti after getProdottiPiatto',this.piatti)
            }
            console.log('this.totaleTesi', this.totaleTesi)
            console.log('this.numPiatti', this.numPiatti)

            this.idDocument = this.$store.state.tesi.Id
            this.filetesi   = this.$store.state.tesi.Nome_File__c
            this.idFile     = this.$store.state.tesi.idFile__c
            this.idFileDoss = this.$store.state.tesi.idFile_Dossier__c
            this.fileDoss   = this.$store.state.tesi.Nome_File_Dossier__c

            this.dossierBloccato = new Date( this.$store.state.tesi.Data_inizio_esame__c ) < this.oggi ? true : false
          } else { // init tesi
            console.log('crea tesi')

            var tesi = {
              Opportunita__c: this.$store.state.idOpptyTesi,
              Name:           'tesi',
              Candidato__c:   this.$store.state.userprofile.Id,
              Tipo_Esame__c:  'Esame Finale'
            }
            if(this.tipo == 'Esame Intermedio') {
              tesi.Name = 'tesi intermedia'
              tesi.Tipo_Esame__c = 'Esame Intermedio'
            }
            this.$store.commit('SET_TESI', tesi)

            this.$store.dispatch('setTesi')
            .then(() => {
              console.log('this.$store.state.tesi',this.$store.state.tesi)
              this.idDocument = this.$store.state.tesi.Id
              this.filetesi   = this.$store.state.tesi.Nome_File__c
              this.totaleTesi = 0
            })
          }

          console.log('idDocument', this.idDocument)

          this.showPiatto = false
          this.loadCondizioni()
        })
      },
      loadCondizioni: function() {
        // var self = this
        console.log('--> loadCondizioni')
        console.log('this.$store.state.tesi',this.$store.state.tesi)

        console.log('idOpptyTesi',this.$store.state.idOpptyTesi)
        this.$store.dispatch('getCondizioni').then(() => {
          var c = this.$store.state.condizione
          console.log('this.$store.state.condizione', c)
          if(c.Budget_Complessivo__c) {
              console.log('budget', c.Budget_Complessivo__c.toFixed(2))
              this.budgetComplessivoTesi = c.Budget_Complessivo__c.toFixed(2)
            }

          if (c && JSON.stringify(this.$store.state.condizione) !== '{}') {
            console.log('ho le condizioni')
            console.log(c)

            this.condvuote = false
            if (c.Link_Corso__c) this.linkguidacorso = c.Link_Corso__c

            var tipipiatto = c.Tipo_Piatto__c

            if (c.Ingredienti_necessari__c   != undefined) this.ingredientiNec  = c.Ingredienti_necessari__c
            if (c.Attrezzatura_Necessaria__c != undefined) this.attrezzaturaNec = c.Attrezzatura_Necessaria__c

            this.tipi = tipipiatto.split(';')

            if ((this.piatti == undefined || this.piatti.length <= 0) && this.$store.state.tesi.Piatti__r) this.piatti = this.$store.state.tesi.Piatti__r.records

            var iPiatti = this.piatti.length

            console.log('iPiatti',iPiatti)
            console.log('this.$store.state.tesi',this.$store.state.tesi)
            this.$set(this.$store.state.tesi, 'iPiatti', iPiatti)
            console.log('c.Numero_Piatti__c',c.Numero_Piatti__c)

            if (iPiatti < c.Numero_Piatti__c) {
              var mancanti = c.Numero_Piatti__c - this.piatti.length
              var ord = this.piatti && this.piatti.length > 0 ? iPiatti + 1 : 1

              for (var i = 0; i < mancanti; i++) {
                var piatto = {
                  Tesi__c:               this.$store.state.tesi.Id,
                  Name:                  c.Nome_piatto__c + '' + ord,
                  Descrizione_Piatto__c: c.Nome_piatto__c + '' + ord,
                  Ordinatore__c:         ord,

                  No_ingredienti_richiesti__c:  false,
                  No_attrezzature_richieste__c: false
                }

                /*
                var ingrattr = c.Ingredienti_necessari__c
                  ? [{
                    Ordinatore__c:  1,
                    Name:           'Ingrediente 1',
                    Note__c:        'Ingrediente 1',
                    Quantita__c:    '1',
                    Tipo__c:        'Ingrediente',
                    valori:         [],
                    primavolta:     true
                  }] : [{
                    Ordinatore__c:  1,
                    Name:           'Attrezzatura 1',
                    Note__c:        'Attrezzatura 1',
                    Quantita__c:    '1',
                    Tipo__c:        'Attrezzatura',
                    valori:         []
                  }]
                */

                console.log('setPiatto loadCondizioni')
                this.$store.dispatch('setPiatto', { piatto: piatto, ingredienti: [] /* ingrattr */ }).then(resp => {
                  console.log('insert')
                  console.log(resp)

                  if (resp.Ingredienti_Attrezzatura__r && resp.Ingredienti_Attrezzatura__r.records) resp.Ingredienti_Attrezzatura__r.records.forEach(d => d.Note__c = '')

                  console.log('resp.Ingredienti_Attrezzatura__r',resp.Ingredienti_Attrezzatura__r)
                  this.piatti.push(resp)
                })
                // console.log('this.piatti',this.piatti)
                ord++
              }
            }
          } else
            this.condvuote = true
        })
      },
      uploadDocument: function(tipo) {
        console.log('open '+tipo)

        this.tipoFile   = tipo
        this.uploadShow = true
      },
      uploadDone: function() {
        this.uploadShow = false
        // need refresh
        this.loadTesi()
      },
      uploadCancel: function() {
        this.uploadShow = false
      },
      aggiungi: function(t) {
        console.log('(Tesi.vue) aggiungi: '+t)
        var o = { Id: '', Note__c: '', Quantita__c: '', valori: [], Prodotto__r: {}, Piatto__c: this.idpiatto, Costo_Quantita__c: ''}

        if (t == 'i') {
          o['Tipo__c'] = 'Ingrediente'
          o.Prodotto__r['Costo__c'] = ''
          this.ingredienti.push(o)
        }
        if (t == 'a') this.attrezzature.push(o)
      },
      rimuovi: function(t, r) {
        console.log('rimouvi t', t)
        console.log('rimouvi r', t)
        console.log('rimouvi this.ingredienti[r]', this.ingredienti[r])
        if (t == 'i') {
          var piattoDaRimuovere = this.ingredienti[r].Piatto__c
          var ingr = this.ingredienti[r]
          console.log('this.piatti', this.piatti)

          for(var piatto of this.piatti) {
            if(piattoDaRimuovere == piatto.Id) {
              if(ingr.Costo_Quantita__c) {
                piatto.totalePiatto -= parseFloat(ingr.Costo_Quantita__c)
                piatto.totalePiatto = (piatto.totalePiatto).toFixed(2)
                this.$store.state.tesi.totaleTesi -= parseFloat(ingr.Costo_Quantita__c)
                this.$store.state.tesi.totaleTesi = this.$store.state.tesi.totaleTesi.toFixed(2)
              }
              console.log('piatto.totalePiatto',piatto.totalePiatto)
              console.log('this.$store.state.tesi.totaleTesi',this.$store.state.tesi.totaleTesi)
              piatto.Ingredienti.splice(r,1)
            }
          }
          // this.ingredienti.splice(r,  1)
          // this.calcolaTotale({Piatto__c: piattoDaRimuovere})
        }
        if (t == 'a') this.attrezzature.splice(r, 1)
      },
      calcolaTotale: function(ingrediente_index) {
        console.log('f --> calcolaTotale')
        var ingrediente = ingrediente_index[0]
        var index = ingrediente_index[1]
        console.log('ingrediente',ingrediente)
        console.log('index',index)

        if (ingrediente.Tipo__c == 'Ingrediente') ingrediente.Quantita__c = parseFloat(ingrediente.Quantita__c).toFixed(3)

        var costoPerQuantita = (ingrediente.Prodotto__r.Costo__c * parseFloat(ingrediente.Quantita__c)).toFixed(2)
        console.log('costoPerQuantita',costoPerQuantita)
        // ingrediente.Costo_Quantita__c = costoPerQuantita
        this.$set(ingrediente, 'Costo_Quantita__c', costoPerQuantita)
        // this.$set(ingrediente.Costo_Quantita__c, costoPerQuantita)
        // this.$set(this.ingredienti[index], 'Costo_Quantita__c', costoPerQuantita)
        // this.$set(this.ingredienti[index].Costo_Quantita__c, costoPerQuantita)

        console.log('this.piatti', this.piatti)
        var totaleTesi = 0
        for(var piatto of this.piatti) {
          console.log('calcolaTotale piatto',piatto)
          var totalePiatto = 0
          if(piatto.Id == ingrediente.Piatto__c) {
            console.log('ids uguali')
            piatto.Ingredienti.forEach(i => {
              if(i.Costo_Quantita__c) {
                console.log('i.Costo_Quantita__c',i.Costo_Quantita__c,typeof(i.Costo_Quantita__c))
                totalePiatto += parseFloat(i.Costo_Quantita__c)
              }
            })
            this.$set(piatto, 'totalePiatto', totalePiatto.toFixed(2))
          } else {
            console.log('ids diversi')
            totalePiatto = piatto.totalePiatto
          }
          totaleTesi += parseFloat(totalePiatto)
        }
        this.$set(this.$store.state.tesi, 'totaleTesi', totaleTesi.toFixed(2))
        if (this.fuoriBudget) console.log('fuori budget')
      },
      editPiatto: function(p) {
        console.log('editPiatto',p)
        // this.$set(this.$store.state.piatto, 'id', p.Id)
        this.idpiatto     = p.Id
        this.nomepiatto   = p.Name.includes(this.$store.state.condizione.Nome_piatto__c)                  ? '' : p.Name
        this.descpiatto   = p.Descrizione_Piatto__c.includes(this.$store.state.condizione.Nome_piatto__c) ? '' : p.Descrizione_Piatto__c
        this.ordinepiatto = p.Ordinatore__c
        this.tiposcelto   = p.Tipo_Piatto__c

        this.noneedIng  = p.No_ingredienti_richiesti__c
        this.noneedAttr = p.No_attrezzature_richieste__c

        // var this = this
        this.ingredienti  = []
        this.attrezzature = []
        for(var piatto of this.piatti) {
          if(piatto.Id == p.Id) {
            
            console.log('piatto.Ingredienti',piatto.Ingredienti)
            console.log('piatto.Attrezzature',piatto.Attrezzature)
            if(piatto.Ingredienti) {
              this.ingredienti = piatto.Ingredienti
              console.log('settato ingredienti')
              this.$set(this, 'ingredienti', piatto.Ingredienti)
              console.log(this.ingredienti)
            }
            if(piatto.Attrezzature) this.attrezzature = piatto.Attrezzature
            // piatto.Ingredienti.forEach(d => {this.$set(d, 'valori', [])})
            // piatto.Attrezzature.forEach(d => {this.$set(d, 'valori', [])})
            if (piatto.Ingredienti_Attrezzatura__r) {
              piatto.Ingredienti_Attrezzatura__r.records.forEach(d => {
                this.$set(d, 'valori', [])
              })
            }
          }
        
        // this.$store.dispatch('getPiatto', p.Id).then(piatto => {
        //   var totalePiatto = 0
          // if (piatto.Ingredienti_Attrezzatura__r) {
          //   piatto.Ingredienti_Attrezzatura__r.records.forEach(d => {
        //       this.$set(d, 'valori', [])
        //       console.log('getPiatto d', d)
        //       if (d.Note__c.includes('Ingrediente') || d.Note__c.includes('Attrezzatura')) d.Note__c = ''
              // if (d.Tipo__c=='Ingrediente') {
        //         if(!d.Ignora_Costo__c) {
        //           d['Costo_Quantita__c'] = d.Quantita__c * d.Prodotto__r.Costo__c
        //           totalePiatto += d.Costo_Quantita__c
        //         }
              //   this.ingredienti.push(d)
              // }
              // if (d.Tipo__c=='Attrezzatura') this.attrezzature.push(d)
          //   })
          // }
        //   piatto['totalePiatto'] = totalePiatto
        //   console.log(piatto)
          this.showPiatto = true

          this.ingredienti.forEach(i  => { if (i.primavolta) i.Note__c = '' })
          this.attrezzature.forEach(a => { if (a.primavolta) a.Note__c = '' })
          // })
        }
      },
      getProdottiPiatto: function(piattoId) {
        console.log('F --> getProdottiPiatto')
        this.$store.dispatch('getPiatto', piattoId).then(piatto => {
          console.log('getPiatto piatto', piatto) 
          var iPiatti = this.piatti.length
          this.piatti.forEach(p => {
            if(p.Id == piatto.Id) {
              console.log('getProdottiPiatto piatto', piatto)
              // p['Ingredienti'] = []
              // p['Attrezzature'] = []
              this.$set(p, 'Ingredienti', [])
              this.$set(p, 'Attrezzature', [])

              var totalePiatto = 0
              console.log('piatto.Ingredienti_Attrezzatura__r',piatto.Ingredienti_Attrezzatura__r)
              if (piatto.Ingredienti_Attrezzatura__r) {
                piatto.Ingredienti_Attrezzatura__r.records.forEach(d => {
                  console.log('getPiatto d', d)

                  console.log('d.Note__c',d.Note__c )
                  if (d.Note__c.includes('Ingrediente') || d.Note__c.includes('Attrezzatura')) d.Note__c = ''

                  if (d.Tipo__c=='Ingrediente') {
                    if(!d.Ignora_Costo__c && d.Quantita__c && d.Prodotto__r.Costo__c) {
                      d['Costo_Quantita__c'] = (d.Quantita__c * d.Prodotto__r.Costo__c).toFixed(2)
                      totalePiatto += d.Quantita__c * d.Prodotto__r.Costo__c
                      // totalePiatto += d.Costo_Quantita__c
                    }
                    p.Ingredienti.push(d)
                  }
                  if (d.Tipo__c=='Attrezzatura') p.Attrezzature.push(d)
                })
              }
              // p['totalePiatto'] = totalePiatto
              this.$set(p, 'totalePiatto', totalePiatto.toFixed(2))
              console.log('totalePiatto',totalePiatto)
              this.totaleTesi += parseFloat(totalePiatto.toFixed(2)) //così non perdo gli arrotondamenti
              console.log('this.totaleTesi',this.totaleTesi)
              this.numPiatti ++
              console.log('numPiatti', this.numPiatti)
              console.log('iPiatti', iPiatti)
              if(this.numPiatti == 1) this.$set(this.$store.state.tesi, 'totaleTesi', 0)
              if(iPiatti == this.numPiatti) this.$set(this.$store.state.tesi, 'totaleTesi', this.totaleTesi.toFixed(2))
              console.log('getProdottiPiatto TOT TESI', this.$store.state.tesi.totaleTesi)
              // console.log(piatto)

              // this.showPiatto = true

              // this.ingredienti.forEach(i  => { if (i.primavolta) i.Note__c = '' })
              // this.attrezzature.forEach(a => { if (a.primavolta) a.Note__c = '' })
              // console.log('after piatto', piatto)
              // this.piatti.forEach(p => { if(p.Id == piattoId) p = piatto })
            }

          })
        })
      },
      gotoUtility: function() {
        window.open(this.linkguidacorso, '_blank')
      }
    }
  }
</script>

<style scoped>
  .dossierbtn {
    font-size: 14px;
  }

  .colright { text-align: right }

  .colpiatto { justify-content: center }

  .piatto {
    padding: 15px;
  }

  .fuoriBudget {
    border: 1px solid red;
    border-radius: 14px;
    background-color: #ffa5a5;
    color:red;
  }

  .textRed {
    color:red;
  }

  @media (max-width: 824px) {
    .dossierbtn {
      width: -webkit-fill-available;
    }

    p {
      font-size: 11px;
    }

    .row {
      margin-right: -12px;
      margin-left:  -12px;
    }

    .inline {
      display:flex;
    }

    .piatto {
      padding: 10px;
      margin: 0px;
    }
  }
</style>