<template>
    <div>
        <h2 class="welcome mb-4">
            {{prod.Name}}
            <span v-if="prod.Info_Prenotazione__c"><br>{{prod.Info_Prenotazione__c}}</span>
        </h2>

        <div class="row mx-auto d-flex justify-content-center">
            <div class="col-lg-4 col-md-4 col-sm-10 col-xs-12">
                <div id="carousel" class="carousel slide" data-ride="carousel">
                    <ol v-if="imgs.length > 1" class="carousel-indicators">
                        <li
                            class="almablue" :class="{ active: img == i }"
                            v-for="(x, i) of imgs" :key="i"
                            :data-slide-to="i" @click="img = i"
                        >
                        </li>
                    </ol>

                    <div class="carousel-inner">
                        <div v-for="(i, id) of imgs" :key="'img_' + id" class="carousel-item" :class="{ active: img == id }">
                            <img :src="i" class="d-blok w-100">
                        </div>
                    </div>

                    <a v-if="imgs.length > 1" class="carousel-control-prev cpoint" role="button" data-slide="prev" @click="img == imgs.length - 1 ? img-- : img++">
                        &#10094;
                    </a>

                    <a v-if="imgs.length > 1" class="carousel-control-next cpoint" role="button" data-slide="next" @click="img == 0 ? img++ : img--">
                        &#10095;
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-10 col-xs-12 mx-3">
                <!-- TORTE -->
                <div hidden v-if="prod.showDetCibo">
                    <div class="row mb-4">
                        <div class="col-12">
                            <b>Peso</b> 900gr.
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b>Disponibilità:</b> {{prod.statoDispo}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <b>Giacenza:</b> {{prod.Quantita_Disponibile__c}}
                        </div>
                    </div>
                </div>

                <!-- CORSI -->
                <!-- <div class="row">
                    <div class="col-12 pt-3">
                        <b>{{$t('Idoneo')}}:</b>
                        <ul>
                            <li v-for="c in prod.corsi" :key="c">{{c}}</li>
                        </ul>
                    </div>
                </div>

                <div hidden class="col-12">
                    <b>{{$t('Colore')}}:</b> Nero
                </div> -->

                <!-- LIBRI -->
                <div hidden class="row mt-5" v-if="prod.showLingua">
                    <div class="col-12 font-weight-bold">Lingua: </div>
                    <div class="col-12">
                        <div class="input-group mb-1">
                            <select id="inputGroupSelect01" class="custom-select custom-select-sm mb-1 w-50" size="1">
                                <option selected>Seleziona</option>
                                <option value="IT">IT</option>
                                <option value="EN">EN</option>
                                <option value="DE">DE</option>
                                <option value="FR">FR</option>
                                <option value="ZH">ZH</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- ABBIGLIAMENTO -->
                <div class="row mt-1" v-if="prod.taglie && prod.taglie.length != 0">
                    <div class="col-6 font-weight-bold">
                        <span v-if="prod.scarpa">{{$t('Numero')}}</span>
                        <span v-else>{{$t('Taglia')}}</span>
                    </div>

                    <div class="col-6">
                        <div class="input-group mb-1">
                            <!-- SCARPE -->
                            <select v-if="prod.scarpa" class="custom-select custom-select-sm mb-1 w-50"
                                    id="inputGroupSelect01" size="1" v-model="prod.numero"
                                    :class="!prod.numero ? 'is-invalid' : 'is-valid'" :title="$t('Scegliunaopzione')" 
                                    @change="setProductCode(prod, prod.numero)" required>
                                <option v-for="v in numeri" :key="v" :value="v">{{$t(v)}}</option>
                            </select>
                            <!-- ABBIGLIAMENTO CON TAGLIE -->
                            <select v-else class="custom-select custom-select-sm mb-1 w-50"
                                    id="inputGroupSelect01" size="1" v-model="prod.taglia"
                                    :class="!prod.taglia ? 'is-invalid' : 'is-valid'" :title="$t('Scegliunaopzione')" 
                                    @change="setProductCode(prod, prod.taglia)" required>
                                <option v-for="v in taglie" :key="v" :value="v">{{v}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- QUANTITà -->
                <div class="row mt-1">
                    <div class="col-6 font-weight-bold">{{$t('Qta')}}</div>
                    <div class="col-6">
                        <div class="form-group">
                            <div class="input-group mb-1">
                                <select class="custom-select custom-select-sm mb-1" id="inputGroupSelect02"
                                        size="1" v-model="prod.qty" :class="!prod.qty ? 'is-invalid' : 'is-valid'"
                                        :title="$t('Scegliunaopzione')" @change="calcTot"
                                        required :disabled="isDisponibile && !((prod.taglia || prod.numero) && prod.taglie && prod.taglie.length != 0)">
                                    <option v-if="isDisponibile" :value="prod.qty">{{ prod.qty }}</option>
                                    <option v-else v-for="q in qty" :key="q" :value="q">{{ q }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="maxQtyNelCarrello" class="col text-right"><h6 class="text-danger">Quantità massima per ordine raggiunta.</h6></div>
                </div>

                <div v-if="prod.Tempo_approvvigionamento__c" class="row mt-1">
                    <div class="col-9 font-weight-bold">{{$t('tempoApprovvigionamento')}}</div>
                    <div class="col-3">
                        <div class="form-group">
                            <div class="input-group mb-1">
                                <p v-if="prod.taglia && prod.mTaglieVarianti[prod.taglia]" style="font-size:15px">{{ prod.mTaglieVarianti[prod.taglia].Tempo_approvvigionamento__c }} gg</p>
                                <p v-else style="font-size:15px">{{ prod.Tempo_approvvigionamento__c }} gg</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="prod.possibilitaRicamo" class="row mb-3">
                    <div class="col">
                        <div class="font-weight-bold text-success">Puoi personalizzare questo capo!</div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col">
                        <p class="border-bottom mt-2 mb-0">
                            <button v-if="prod.possibilitaRicamo" class="btn border-primary" @click="attivaSezione('ricamo', !showRicamo)">
                                {{$t('ricamo')}} <i class="bi" :class="showRicamo? 'bi-caret-up-fill': 'bi-caret-down-fill'"></i>
                            </button>
                            <button class="btn" :class="prodSelezionato.Dettagli_prodotto__c ? 'border-primary' : 'border disabled'" @click="attivaSezione('dettagli', !showDettaglio)">
                                {{$t('dettagliProdotto')}} <i class="bi" :class="showDettaglio? 'bi-caret-up-fill': 'bi-caret-down-fill'"></i>
                            </button>
                            <button class="btn" :class="{ 'border-primary': hasCombinedAttachments, 'border disabled': !hasCombinedAttachments, 'pe-none':disableAtt }"
                            @click="getCombinedAttachments()">
                                {{$t('schedaTecnica')}} <i class="bi" :class="showFile? 'bi-caret-up-fill': 'bi-caret-down-fill'"></i>
                            </button>
                        </p>
                        <div v-if="prod.possibilitaRicamo && showRicamo">
                            <div class="card card-body">
                                <p>Inserisci personalizzazione bla bla bla</p>
                                <input maxlength="100" type="text" v-model="prod.testoRicamo"  class="form-control" placeholder="Personalizzazione" />
                            </div>
                        </div>
                        <div v-if="prodSelezionato.Dettagli_prodotto__c && showDettaglio"><div class="card card-body">{{ prodSelezionato.Dettagli_prodotto__c }}</div></div>
                        <div v-if="hasCombinedAttachments && showFile">
                            <div class="card card-body">
                                <ul>
                                    <li v-for="f of this.files" :key="f.Id">
                                        <a :href="'data:application/pdf;base64,'+f.Body" :download="f.Name" target="_blank">{{ f.Name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12 font-weight-bold text-right display-4">{{total | amount}}</div>
                </div>

                <div class="row pt-3" style="border-top: 1px solid #d29500">
                    <div class="col-6 font-weight-bold text-right">
                        <button :disabled="isDisponibile" @click.prevent="addToCart(prod)" type="button" class="btn btn-primary">
                            <small>
                                {{$t('Aggiungicarrello')}}
                                <svg style="vertical-align: sub" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                            </small>
                        </button>
                    </div>
                    <div class="col-6 font-weight-bold text-right">
                        <button :disabled="isDisponibile" @click.prevent="gotoCart(prod)" type="button" class="btn btn-primary float-right">
                            <small>
                                {{$t('Acquistorapido')}}
                                <svg style="vertical-align: sub" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                            </small>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--
            <div class="row mx-auto">
                <div class="col-12 mt-3">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button
                                        class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"
                                    >
                                        {{$t('Dettagli Prodotto')}}
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <ul>
                                        <li v-if="prod.ProductCode">{{$t('CodiceArticolo')}}: {{prod.ProductCode}}</li>
                                        <li>{{$t('Composizione')}}: 41% cotone / 59% tencel (210 g/m²)</li>
                                        <li>{{$t('Chiusura')}}: Bottoni Clip</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button
                                    class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"
                                >
                                    Prodotti correlati
                                </button>
                            </h2>
                        </div>

                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                <div class="row mx-auto">
                                    <div class="col-lg-2 col-md-3 col-sm-10 col-xs-6">
                                        <div class="card pointer">
                                            <div class="grigliaCommerceDiv1">
                                                <div class="grigliaCommerceDiv2">
                                                    <img
                                                        :src="img"
                                                        alt="..." class="p-2" style="max-width: 100%; max-height: 100%"
                                                    >
                                                </div>
                                            </div>

                                            <div class="card-body">
                                                <p class="card-text">Dotazione Giacca - Corso di Pasticceria</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        -->

        <!-- BLOCCO UNO -->
        <div class="row mx-auto"></div>
    </div>
</template>

<script>
    import numeral from 'numeral'

    //const noimgsrc = 

    export default {
        name: 'ShopProduct',
        props: {
            campi:  {},
            // taglie: [],
            // numeri: [],
            prod:   Object,
            taglie: Array,
            numeri: Array,
        },
        data: function() {
            return {
                qty:   10,
                total: 0,

                imgs: [''],
                img:  0,

                noimg: require('../assets/no-image-found-ecommerce.png'),

                showRicamo: false,
                showDettaglio: false,
                showFile: false,
                disableAtt: false,
                files: [],
            }
        },
        created: function () {
            // if (this.taglie == []) this.prod.taglia = '*'
            console.log('this.prod in shopproduct',this.prod)
            console.log(this.prod.qty +' == '+ this.prod.Max_Acquisti_Per_Ordine__c)

            if(this.prod.Max_Acquisti_Per_Ordine__c) {
                this.qty = Number(this.prod.Max_Acquisti_Per_Ordine__c)

                for(var p of this.$store.state.carrelloEcommerce) {
                    console.log(p.ProductCode +' == '+ this.prod.ProductCode +' && '+ p.qty +' == '+ this.prod.Max_Acquisti_Per_Ordine__c)
                    if(p.ProductCode == this.prod.ProductCode) {
                        this.prod.qty = p.qty
                        this.qty = Number(this.prod.Max_Acquisti_Per_Ordine__c) - p.qty
                        break
                    }
                }

                if(!this.prod.qty) this.prod.qty = 0
            } else this.prod.qty = 1

            if(this.prod.testoRicamo) this.attivaSezione('ricamo', true)

            this.total = this.prod.Prezzo__c

            this.getImages()
        },
        computed: {
            isDisponibile: function() {
                if ((this.prod.Quantita_Disponibile__c < 1 || this.prod.Quantita_Disponibile__c == undefined) && this.prod.Family == 'Torta') return true
                if (!this.prod.qty && !this.prod.Max_Acquisti_Per_Ordine__c) return true
                if (this.maxQtyNelCarrello) return true
                if (this.prod.taglie && this.prod.taglie.length != 0) if (!this.prod.taglia && !this.prod.scarpa) return true
                if (!this.prod.numero && this.prod.scarpa)  return true
                return false
            },
            maxQtyNelCarrello: function () {
                for(var p of this.$store.state.carrelloEcommerce) {
                    if(p.ProductCode == this.prod.ProductCode && p.qty == this.prod.Max_Acquisti_Per_Ordine__c) {
                        return true
                    }
                }
                return false
            },
            prodSelezionato() {
                if(this.taglie && this.prod.taglia) return this.prod.mTaglieVarianti[this.prod.taglia]
                return this.prod
            },
            hasCombinedAttachments() {
                if(this.prodSelezionato.CombinedAttachments && this.prodSelezionato.CombinedAttachments.totalSize > 0) return true
                return false
            }
        },
        filters: {
            amount: function(v) {
                return numeral(v).format('0,0[.]00 $')
            }
        },
        methods: {
            setProductCode: function(prod, taglia) {
                if(prod.mTaglieVarianti[taglia]) {
                    this.prod.ProductCode = prod.mTaglieVarianti[taglia].ProductCode
                    if(this.prod.img == undefined) this.prod['img'] = this.imgs[0]
                } else console.log('ProductCode della Taglia/Numero non trovato')
            },
            gotoScheda: function() {
                console.log('--> gotoScheda')
                this.$router.push({ name: 'ShopProduct' })
            },
            gotoCart: function() {
                console.log('--> gotoCart')
                this.$emit('gotoCarrello', this.prod)
            },
            addToCart: function(prod) {
                console.log('--> addtoCart')
                console.log('prod',prod)
                this.qty = Number(this.prod.Max_Acquisti_Per_Ordine__c) - this.prod.qty
                this.$store.dispatch('addProd', prod).then(() => this.$emit('addedToCart'))
            },
            calcTot: function() {
                console.log('--> calcTot qty',typeof(this.prod.qty), typeof(this.prod.Max_Acquisti_Per_Ordine__c))
                this.total = this.prod.Prezzo__c * this.prod.qty
            },
            getImages: async function() {
                console.log('shopproduct.getImages',this.prod.Name, this.prod.Id)
                this.imgs = []

                if (this.prod.hasImage) {
                    this.$store.dispatch('getImage', {id:this.prod.Id, limit: false}).then(res => {
                        this.imgs = res
                        console.log(res)
                    })
                } else {
                    this.imgs = [ this.noimg ]
                }
            },
            attivaSezione(sezione, value) {
                this.showRicamo = false
                this.showDettaglio = false
                this.showFile = false
                switch (sezione) {
                    case 'ricamo':
                        this.showRicamo = value
                        break;
                    case 'dettagli':
                        this.showDettaglio = value
                        break;
                    case 'tecnica':
                        this.showFile = value
                        break;
                }
            },
            getCombinedAttachments() {
                console.log('--> getCombinedAttachments')
                this.disableAtt = true
                this.files = []
                for(var ca of this.prodSelezionato.CombinedAttachments.records) {
                    var callname = ''
                    if(ca.RecordType == 'Attachment') callname = 'getAttachment'
                    if(ca.RecordType == 'File') callname = 'getContentDocument'

                    if(callname != '') {
                        this.$store.dispatch( callname , { idFile : ca.Id })
                        .then(res => {
                            console.log
                            this.files.push({
                                Id: ca.Id,
                                Name: ca.Title,
                                Body: res
                            })
                            this.disableAtt = false
                            console.log('this.disableAtt',this.disableAtt)
                            this.attivaSezione('tecnica', !this.showFile)
                        })
                    }
                }
            }
        }
    }
</script>

<style>
    .almablue { background-color: #314361 !important }

    .cpoint { cursor: pointer }

    .pe-none { pointer-events: none}
</style>