export default {
    "subtitleCandidatura": "CHINA - Area Candidatura",
    "ATTENZIONE": "CHINA - ATTENZIONE!",
    "caroUtente": "CHINA - Gentile Utente",
    "avvisoInizioProcessoCandidatura" : "CHINA - Prima di iniziare il processo di candidatura, accertati di avere a disposizione il tuo CV in formato PDF: ti verrà infatti chiesto di caricarlo nell’ultimo passaggio, dopo i tuoi dati anagrafici.",
    "SCARICAMODELLOCV": "CHINA - SCARICA MODELLO CV",
    "INIZIA": "CHINA - INIZIA",
    "DatiPersonali": "CHINA - Dati Personali",
    "Nome": "CHINA - Nome",
    "Cognome": "CHINA - Cognome",
    "Sesso": "CHINA - Sesso",
    "F": "CHINA - F",
    "M": "CHINA - M",
    "Telefono": "CHINA - Telefono",
    "Cellulare": "CHINA - Cellulare",
    "AltroCellulare": "CHINA - Altro Cellulare",
    "E-Mail": "CHINA - E-Mail",
    "MailSecondaria": "CHINA - E-Mail Secondaria",
    "Conferma Email": "CHINA - Conferma E-Mail",
    "Successivo": "CHINA - Successivo",
    "Indietro": "CHINA - Indietro",
    "Anagrafica": "CHINA - Anagrafica",
    "DatadiNascita": "CHINA - Data di Nascita",
    "CodiceFiscale": "CHINA - Codice Fiscale",
    "haiCF" : "Do you have an Italian Tax Payer Code?",
    "CittàdiNascita": "CHINA - Città di Nascita",
    "Città": "CHINA - Città",
    "ProvinciadiNascita": "CHINA - Provincia di Nascita",
    "Scegliunaopzione": "CHINA - Scegli una opzione",
    "NazionediNascita": "CHINA - Nazione di Nascita",
    "IndirizzoResidenza": "CHINA - Indirizzo Residenza",
    "CittàdiResidenza": "CHINA - Città di Residenza",
    "CAP": "CHINA - CAP",
    "ProvinciadiResidenza": "CHINA - Provincia di Residenza",
    "NazionediResidenza": "CHINA - Nazione di Residenza",
    "Cittadinanza": "CHINA - Cittadinanza",
    "SecondaCittadinanza": "CHINA - Seconda Cittadinanza",
    "Studi": "CHINA - Studi",
    "QualificaoTitolodiStudio": "CHINA - Qualifica o Titolo di Studio",
    "IndirizzodiStudio": "CHINA - Indirizzo di Studio",
    "HotelManagementCulinaryArtsDegree": "CHINA - Hotel Management and Culinary Arts Degree",
    "Si": "CHINA - Si",
    "No": "CHINA - No",
    "Altro":"CHINA - Altro",
    "Annoconseguimentoqualifica": "CHINA - Anno conseguimento qualifica",
    "NazioneIstituto": "CHINA - Nazione Istituto",
    "CittàIstituto": "CHINA - Città Istituto",
    "NomeIstituto": "CHINA - Nome Istituto",
    "FrequentatoAlma": "CHINA - Have you ever attended any other cooking schools?",
    "AltraScuolaCucina": "CHINA - Quale scuola di cucina hai frequentato?",
    "ConosciutoAlma": "CHINA - Come hai conosciuto Alma?",
    "FilecaricareLabel": "CHINA - E' consigliato il formato PDF, non oltre 4.5 MB",
    "Dimensioni": "CHINA - Dimensioni:",
    "DimensioniEccessive": "CHINA - La dimensione del file supera il limite massimo consentito, anche se il limite è indicativo riduci la dimensione del file.",
    "InserireCurriculum": "CHINA - Inserire Curriculum",
    "CaricaCurriculum": "CHINA - Carica Curriculum",
    "Documento di riconoscimento": "CHINA - Documento di riconoscimento",
    "TipoDocumento": "CHINA - Tipo Documento",
    "Numero": "CHINA - Numero",
    "DataRilascio": "CHINA - Data di Rilascio",
    "DataScadenza": "CHINA - Data di Scadenza",
    "NazioneRilascio": "CHINA - Nazione di rilascio",
    "CaricaDocumentoIdentita": "CHINA - Carica Documento",
    "FilecaricareDocLabel": "CHINA - E' consigliato il formato PDF o JPEG, non oltre 4.5 MB",
    "Letuecredenziali": "CHINA - Le tue credenziali",
    "TestoRegistrazione": "CHINA - Inserisci ora la tua mail come username e una password a tua scelta per registrarti alla tua area personale e procedere nella candidatura",
    "ConfermaPassword": "CHINA - Conferma Password",
    "ModificaPasswordLabel": "CHINA - Se hai dimenticato la password puoi modificarla nella sezione Profilo Personale una volta conclusa la candidatura.",
    "Fine": "CHINA - Fine!",

    // titolo di studio
    "Diploma di Scuola Superiore": "High School Diploma",
    "Laurea triennale": "Bachelor's Degree",
    "Laurea magistrale": "Master's Degree",
    "Non ho alcun diploma di maturità": "I don't have any High School Diploma",
    "Altro (specificare)": "Other (please specify)",
    "Istituto Alberghiero": "Hotel or Culinary Art High School student",

    // conosciuto alma
    "Ho partecipato ad un corso ALMA in passato":"",
    "Ho partecipato a un open day ALMA":"",
    "Ho visitato lo stand di ALMA alla fiera Job Orienta a Verona":"",
    "Me l’ha consigliata un amico che ha frequentato ALMA":"",
    "Me l’ha consigliata uno chef":"",
    "L’ho trovata con una ricerca su internet":"",
    "Seguo ALMA sui social":"",
    "ALMA ha visitato la scuola che sto frequentando":"",
    "Ho partecipato al Cooking Quiz di ALMA nella mia scuola":"",
    "L’ho vista in TV":"",
    "L'ho sentita in radio":"",

    "Carta d'identità": "CHINA - Identity card",
    "Passaporto": "CHINA - Passport",

    // NAZIONI - NAZIONALITà
    "Afghanistan":"Afghanistan",
    "Albania":"Albania",
    "Algeria":"Algeria",
    "Samoa Americane":"American Samoa",
    "Andorra":"Andorra",
    "Angola (compresa Cabinda)":"Angola",
    "Antigua E Barbuda":"Antigua and Barbuda",
    "Armenia":"Armenia",
    "Argentina":"Argentina",
    "Australia":"Australia",
    "Austria":"Austria",
    "Azerbaigian":"Azerbaijan",
    "Bahamas":"Bahamas",
    "Bahrein":"Bahrain",
    "Bangladesh":"Bangladesh",
    "Barbados":"Barbados",
    "Belarus":"Belarus",
    "Belgio":"Belgium",
    "Belize":"Belize",
    "Bhutan":"Bhutan",
    "Bolivia":"Bolivia",
    "Bosnia-erzegovina":"Bosnia and Herzegowina",
    "Botswana":"Botswana",
    "Brasile":"Brazil",
    "Brunei":"Brunei Darussalam",
    "Bulgaria":"Bulgaria",
    "Burkina-faso":"Burkina Faso",
    "Burundi":"Burundi",
    "Cambogia":"Cambodia",
    "Camerun":"Cameroon",
    "Canada":"Canada",
    "Capo Verde":"Cape Verde",
    "Repubblica Centrafricana":"Central African Republic",
    "Ciad":"Chad",
    "Cile":"Chile",
    "Cina":"China",
    "Colombia":"Colombia",
    "Comore":"Comoros",
    "Costarica":"Costa Rica",
    "Costa D'avorio":"Cote D'Ivoire",
    "Croazia":"Croatia",
    "Cuba":"Cuba",
    "Cipro":"Cyprus",
    "Repubblica Ceca":"Czech Republic",
    "Danimarca":"Denmark",
    "Dominica":"Dominica",
    "Repubblica Dominicana":"Dominican Republic",
    "Ecuador":"Ecuador",
    "Egitto":"Egypt",
    "El Salvador":"El Salvador",
    "Guinea Equatoriale":"Equatorial Guinea",
    "Eritrea":"Eritrea",
    "Estonia":"Estonia",
    "Etiopia":"Ethiopia",
    "Figi":"Fiji",
    "Finlandia":"Finland",
    "Francia (compreso Monaco E Dipartimenti Oltemare)":"France",
    "Gabon":"Gabon",
    "Gambia":"Gambia",
    "Georgia":"Georgia",
    "Germania":"Germany",
    "Ghana":"Ghana",
    "Grecia":"Greece",
    "Grenada":"Grenada",
    "Guatemala":"Guatemala",
    "Guinea":"Guinea",
    "Guinea Bissau":"Guinea-bissau",
    "Guyana":"Guyana",
    "Haiti":"Haiti",
    "Honduras (compreso Isole Swan)":"Honduras",
    "Hong Kong":"Hong Kong",
    "Ungheria":"Hungary",
    "Islanda":"Iceland",
    "India":"India",
    "Indonesia":"Indonesia",
    "Iran":"Iran (Islamic Republic of)",
    "Iraq":"Iraq",
    "Irlanda":"Ireland",
    "Israele":"Israel",
    "Italia":"Italy",
    "Giamaica":"Jamaica",
    "Giappone":"Japan",
    "Giordania":"Jordan",
    "Kazakistan":"Kazakhstan",
    "Kenya":"Kenya",
    "Kiribati":"Kiribati",
    "Kuwait":"Kuwait",
    "Kirghizistan":"Kyrgyzstan",
    "Laos":"Lao People's Democratic Republic",
    "Lettonia":"Latvia",
    "Libano":"Lebanon",
    "Lesotho":"Lesotho",
    "Liberia":"Liberia",
    "Libia":"Libyan Arab Jamahiriya",
    "Liechtenstein":"Liechtenstein",
    "Lituania":"Lithuania",
    "Lussemburgo":"Luxembourg",
    "Macao":"Macau",
    "Macedonia, the former Yugoslav Republ. Of":"Macedonia, The Former Yugoslav Republic of",
    "Madagascar":"Madagascar",
    "Malawi":"Malawi",
    "Malaysia Peninsulare Ed Orientale":"Malaysia",
    "Maldive":"Maldives",
    "Mali":"Mali",
    "Malta (gozo, Comino)":"Malta",
    "Isole Marshall":"Marshall Islands",
    "Mauritania":"Mauritania",
    "Maurizio Isola":"Mauritius",
    "Messico":"Mexico",
    "Federazione Degli Stati Di Micronesia":"Micronesia, Federated States of",
    "Moldavia":"Moldova, Republic of",
    "Monaco":"Monaco",
    "Mongolia":"Mongolia",
    "Marocco":"Morocco",
    "Mozambico":"Mozambique",
    "Myanmar":"Myanmar",
    "Namibia":"Namibia",
    "Nauru":"Nauru",
    "Nepal":"Nepal",
    "Antille Olandesi":"Netherlands",
    "Nuova Zelanda":"New Zealand",
    "Nicaragua (compreso Isole Corn)":"Nicaragua",
    "Niger":"Niger",
    "Nigeria":"Nigeria",
    "Norvegia (compresa Jan Mayen;escluso Arc.svalbard)":"Norway",
    "Oman":"Oman",
    "Pakistan":"Pakistan",
    "PALAU":"Palau",
    "Panama":"Panama",
    "Papuasia - Nuova Guinea":"Papua New Guinea",
    "Paraguay":"Paraguay",
    "Peru'":"Peru",
    "Filippine":"Philippines",
    "Polonia":"Poland",
    "Portogallo":"Portugal",
    "Qatar":"Qatar",
    "Romania":"Romania",
    "Russia":"Russian Federation",
    "Ruanda":"Rwanda",
    "San Cristoforo E Nevis":"Saint Kitts and Nevis",
    "Saint Lucia":"Saint Lucia",
    "Saint Vincent":"Saint Vincent and the Grenadines",
    "Samoa Occidentali":"Samoa",
    "San Marino":"San Marino",
    "Sao Tome' E Principe":"Sao Tome and Principe",
    "Arabia Saudita":"Saudi Arabia",
    "Senegal":"Senegal",
    "Seychelles E Dipendenze":"Seychelles",
    "Sierra Leone":"Sierra Leone",
    "Singapore":"Singapore",
    "Slovacchia":"Slovakia (Slovak Republic)",
    "Slovenia":"Slovenia",
    "Isole Salomone":"Solomon Islands",
    "Somalia":"Somalia",
    "Sud Africa":"South Africa",
    "Spagna (compr.baleari,canarie,ceuta,melilla)":"Spain",
    "Sri Lanka":"Sri Lanka",
    "Sudan":"Sudan",
    "Suriname":"Suriname",
    "Svezia":"Sweden",
    "Svizzera":"Switzerland",
    "Siria":"Syrian Arab Republic",
    "Taiwan":"Taiwan",
    "Tanzania (tanganica, Zanzibar, Penba)":"Tanzania, United Republic of",
    "Thailandia":"Thailand",
    "Togo":"Togo",
    "Tonga":"Tonga",
    "Trinidad E Tobago":"Trinidad and Tobago",
    "Tunisia":"Tunisia",
    "Turchia":"Turkey",
    "Turkmenistan":"Turkmenistan",
    "Tuvalu":"Tuvalu",
    "Uganda":"Uganda",
    "Ucraina":"Ukraine",
    "Emirati Arabi Uniti":"United Arab Emirates",
    "Regno Unito (Gran Bretagna, Irlanda Nord)":"United Kingdom",
    "Stati Uniti d'America":"United States",
    "Uruguay":"Uruguay",
    "Uzbekistan":"Uzbekistan",
    "Vanuatu":"Vanuatu",
    "Citta' Del Vaticano":"Vatican City State (Holy See)",
    "Venezuela":"Venezuela",
    "Vietnam":"Viet Nam",
    "Yemen":"Yemen",
    "Serbia":"Serbia",
    "Congo (repubblica)":"The Democratic Republic of Congo (ex Zaire)",
    "Zambia":"Zambia",
    "Zimbabwe":"Zimbabwe",
    "Montenegro":"Montenegro",
    "Palestinian Territory, Occupied":"Territori dell'Autonomia Palestinese",
    "Timor-Leste":"Timor Orientale",
    "Repub. Federale Di Jugoslavia (serbia,montenegro)":"Jugoslavia (Serbia-Montenegro)",
    "Apolide":"Apolide",

}